.shift{
    
    @include container(100%);
    position: relative;
    
    @include breakpoint($medium){
        padding: 0;
    }

    &__struttura{
        @include container(1286px);
    }

    &__content{
        width: 100%;
        color: $font-color;
        font-size: rem(16);
        line-height: rem(28);
        letter-spacing: 0.04px;
        font-weight: 200;
        padding: rem(40);
        @include breakpoint($medium){
            min-height: (3.5/4) * 50vw;
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
            > * {
                width: 100%;
                flex: 0 0 auto;
            }
            width: span(42%);
            padding: rem(80) rem(40);
        }

        @include breakpoint($large){
            padding: rem(130) rem(70);
        }

        [dir="rtl"] & {
            float: left;
        }

        .shift--inverted &{
            float: right;
            [dir="rtl"] & {
                float: right;
            }
        }

        h2{
            font-family: $header-font;
            font-size: rem(50);
            line-height: rem(45);
            letter-spacing: 0.02px;
            color: $blu;
            font-weight: 700;
            text-transform: uppercase;
        }

        h3{
            font-size: rem(16);
            line-height: rem(28);
            letter-spacing: 0.06px;
            text-transform: uppercase;
            padding-bottom: 0.3em;
        }

        p{
            padding-bottom: 0.3em;
        }

        ul:not(.slick-dots){
        font-size: rem(16);
        line-height: rem(28);
        padding-top: rem(12);
        font-weight: 200;

        li{
            position: relative;
            padding: 0 0 0 rem(15);
            margin-bottom: rem(6);

            &:before{
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                background-color: $blu;
                border-radius: 100%;
                top: rem(12);
                left: 0;
                }
            }
        }
    }

    &__visual{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        height: 120px;
        position: relative;
        &--slider {
            height: 75vw;
            overflow: visible;
            @include breakpoint($medium){
                .slick-prev, .slick-next {
                    top: 50%;
                    left: 0;
                    .shift--inverted & {
                        left: auto;
                        right: 0;
                    }
                }
                .slick-prev {
                    transform: translate3d(-50%, -100%, 0);
                    .shift--inverted & {
                        transform: translate3d(50%, -100%, 0);
                    }
                }
                .slick-next {
                    transform: translate3d(-50%, 100%, 0);
                    .shift--inverted & {
                        transform: translate3d(50%, 100%, 0);
                    }
                }
            }
        }

        @include breakpoint($medium){
            width: 52%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
        }

        .shift--inverted &{
            right: auto;
            left: 0;
        }
        img {
            display: none;
        }
    }

    &__slider, &__slider .slick-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        .slick-track {
            height: 100%;
        }
    }
    &__slide {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__dida {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: transparentize($blu, .15);
        color: white;
        padding: 10px 10px 30px;
        font-size: rem(24);
        font-style: italic;
        font-weight: 400;
        line-height: rem(24);
        z-index: 200;
        @include breakpoint($medium) {
            padding: 20px 100px;
        }
    }
}

.flex__video {
    margin: rem(40) auto;
    height: calc(100vh - #{rem(134)});
    max-width: 1286px;
    position: relative;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100% !important;
        width: 100% !important;
    }
}

.flex__slider {
    height: 75vw;
    position: relative;
    margin: rem(40) auto;
    max-width: 1286px;
    img {
        display: none;
    }
    @include breakpoint($medium) {
        height: calc(100vh - #{rem(134)});
        margin: rem(80) auto;
    }
    &--split {
        height: auto;
        min-height: calc(100vh - #{rem(134)});
        .shift__slider, .shift__slider .slick-list {
            position: relative;
            height: auto;
        }
        @include breakpoint($medium){
            .shift__slider, .shift__slider .slick-list {
                position: absolute;
                height: 100%;
            }
            .slick-prev, .slick-next {
                top: 50%;
                left: 50%;
            }
            .slick-prev {
                transform: translate3d(-50%, -100%, 0);
            }
            .slick-next {
                transform: translate3d(-50%, 100%, 0);
            }
        }
    }
    .shift__slide--columns {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        .slide__image {
            flex: 0 0 auto;
            width: 100%;
            height: 75vw;
            background: {
                position: center;
                repeat: no-repeat;
                size: cover;
            }
        }
        .slide__dida {
            flex: 0 0 auto;
            width: 100%;
            padding: rem(50);
            font-size: rem(22);
            line-height: rem(33);
            color: $font-color;
            letter-spacing: 0.04px;
            font-weight: 200;
        }
        @include breakpoint($medium) {
            .slide__image {
                height: calc(100vh - #{rem(134)});
            }
            .slide__image, .slide__dida {
                width: 50%;
            }
        }
    }
}