.coppiaimg{
    @include container(1286px);
    &:before, &:after {
        display: none;
    }
    @include breakpoint($medium){
        padding-bottom: rem(0);
    }
    
    & + .bottoncioni-container {
        margin-bottom: rem(80);
    }
    $max-height: calc((1286px/2) - #{rem(30)});
    &__testo {
        padding: rem(40) rem(20);
        font-size: rem(16);
            //line-height: rem(28);
        line-height: rem(23);
        font-weight: 200;
    }
    display: flex;
    flex-wrap: wrap;
    &--slider {
        margin-bottom: rem(80);
    }
    .coppiaimg__col {
        width: 100%;
        flex: 0 0 auto;
        @include breakpoint($medium){
            
            @include span(6 of 12 0.4 after);
            // height: 100vh;
            // max-height: 50vw;
        }

        // @include breakpoint(1286px) {
        //     max-height: $max-height;
        // }

        &:last-child{

            @include breakpoint($medium){
                @include span(6 of 12 0.4 after last);
            }
        }
    }
    .coppiaimg__img{
        &:before {
            padding-top: 100%;
            display: block;
            content: '';
        }
        background-size: cover;
        background-position: center center;
        
        
    }
    .coppiaimg__slider {
        //height: 75vw;
        width: 100%;
        .shift__slide {
            position: relative;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        &:before {
            padding-top: 100%;
            display: block;
            content: '';
        }
        overflow: visible;
        position: relative;
        .slick-prev, .slick-next {
            bottom: 0;
            top: auto;
            left: 50%;
            margin: 0 0 0 rem(-20);
            // .shift--inverted & {
            //     left: auto;
            //     right: 0;
            // }
        }
        .slick-prev {
            transform: translate3d(-100%, 50%, 0);
        }
        .slick-next {
            transform: translate3d(100%, 50%, 0);
        }

        
       
        // @include breakpoint(1286px) {
        //     max-height: $max-height;
        // }
    }
    &[dir="ltr"]{
        .slick-slide {
            float: left!important;
        }
    }
}