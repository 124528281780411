.pulsante{
    display: inline-block;
    border: 1px solid $blu;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    padding: rem(18) rem(46);
    text-align: center;

    @include breakpoint($medium){
        padding: rem(24) rem(58);
        margin: 0;
    }

    &:hover{
        background-color: $blu;
    }

    &__testo{
        font-size: rem(16);
        line-height: 1.4;
        letter-spacing: rem(0.08);
        text-transform: uppercase;
        font-weight: 400;
        color: $blu;
        transition: all 0.3s ease-in-out;

        .pulsante:hover &{
            color: #fff;
        }
    }
}