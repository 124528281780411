.page-header{
    
    @include container(1286px);
    height: 350px;
    background-size: cover;
    background-position: center center;

    @include breakpoint($medium){
        height: 600px
    }
}