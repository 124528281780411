.lefaq{
    @include container(1286px);
    padding: rem(50) rem(40) rem(50);

    @include breakpoint($medium){
        padding-top: rem(130);
        padding-bottom: rem(150);
        padding-left: 0;
    }

    &__sidebar{
        
        @include breakpoint($medium){
            @include span(3 of 12 0 after);
            padding: 0 0 0 rem(40);
        }

        h1{
            @extend %page_title;
            display: block;
            text-transform: uppercase;
            padding-bottom: rem(50);

            @include breakpoint($medium){
                padding-bottom: 0;
            }
        }
    }

    &__list{
        background-color: #fff;
        @include breakpoint($medium){
            @include span(9 of 12 0 after);
        }
    }
}
