%page_title {
	padding: 0.2em 0;
    font-family: $header-font;
    font-size: rem(80);
    line-height: rem(72);
    font-weight: 700;
    letter-spacing: 0.04px;
    color: $blu;    
    [lang="ru-RU"] & {
        font-size: rem(40);
        line-height: rem(34);
    }
}
%sub_title {
	font-family: $header-font;
    font-size: rem(55);
    line-height: rem(50);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.04px;
    color: $blu;       
    [lang="ru-RU"] & {
        font-size: rem(32);
        line-height: rem(28);
    }
}
@import "modal";
@import "accordion";
@import "bloglist";
@import "bottoncioni";
@import "briciole";
@import "calltoaction";
@import "configuratore";
@import "contatti";
@import "coppiaimg";
@import "faq";
@import "focus";
@import "fonts";
@import "footer";
@import "form-pen";
@import "header";
@import "homepage-cta";
@import "homepage-intro";
@import "macchina";
@import "marcatrici";
@import "news";
@import "page-header";
@import "page-title";
@import "paragrafo";
@import "pulsante";
@import "shift";
@import "slick-theme";
@import "tabella";
@import "tinymce";
@import "video-hero";
@import "wrapper";
@import "masked";
// @import "natural-form";