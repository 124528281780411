.paragrafo{
    @include container(1286px);
    font-size: rem(22);
    line-height: rem(33);
    font-weight: 200;
    letter-spacing: 0.04px;
    color: $font-color;
    padding: rem(40) rem(40);

    @include breakpoint($medium){
        padding: rem(80) rem(120);
    }

    &--padding {
        &-top {
            padding-bottom: 0 !important;
        }
        &-bottom {
            padding-top: 0 !important;
        }
        &-no {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    h2{
        @extend %page_title;
        text-transform: uppercase;
    }

    h3{
        @extend %sub_title;
    }

    h4{
        font-size: rem(30);
        line-height: rem(45);
        font-weight: 300;
        letter-spacing: 0.04px;
        margin-bottom: rem(30);

        @include breakpoint($medium){
            opacity: 0;
            transition: opacity 0.3s ease-out;
        }

        &.is-visible{

            @include breakpoint($medium){
                opacity: 1;
            }
        }
    }

    p{
        @include breakpoint($medium){
            opacity: 0;
            transition: opacity 0.8s ease-out;
        }

        &.is-visible{

            @include breakpoint($medium){
                opacity: 1;
            }
        }
    }
}
