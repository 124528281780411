.homepage-intro{
    @include container(1286px);
    padding: rem(40);

    @include breakpoint($medium){
        padding: 200px 0 0 0;
    }

    &__title{
        @extend %page_title;
        padding-bottom: 0.4em;

        @include breakpoint($medium){
            @include span(6 of 12 at 7 isolate);

            padding: 0 rem(40);
        }
    }

    &__subtitle{
        font-family: $body-font;
        letter-spacing: 0.04;

        @include breakpoint($medium){
            @include span(6 of 12 at 1 isolate);

            padding: 0 rem(40) rem(185);
            color: $font-color;
        }

        h2{
            font-size: rem(22);
            line-height: rem(33);
            padding-bottom: rem(34);
        }

        h3{
            font-size: rem(30);
            line-height: rem(45);
        }
    }
}