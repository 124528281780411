$p: 50px;
%mask_bg {
	position: relative;
	&:before {
		position: absolute;
		top: 0;
		left: 50%;
		width: 50%;
		height: 100%;
		transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
		transform: scaleX(1) translateZ(0);
		transform-origin: right center;
		background-color: #f8f9f9;
		content: '';	
		transition: {
			property: transform;
			duration: 1.5s;
		}
	}
}
%transform_left {
	transform: translate3d($p*-1, 0, 0);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	opacity: 0;
	transition: {
		property: transform, opacity;
		duration: 1.5s;
	}
		
}
%transform_right {
	transform: translate3d($p, 0, 0);
	opacity: 0;
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition-delay: 0.5s;
	transition: {
		property: transform, opacity;
		duration: 1.5s;
	}
}

%transform_child_left {
	> * {
		transform: translate3d((($p/2)*-1), 0, 0);
		transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
		transition-property: transform;
		transition-duration: 1s;
		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				$d: (0.15s * $i);
				transition-delay: $d;
			}
		}
	}	
}

%transform_child_right {
	> * {
		transform: translate3d(($p/2), 0, 0);
		transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
		transition-property: transform;
		transition-duration: 1s;
		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				$d: 0.5s + (0.15s * $i);
				transition-delay: $d;
			}
		}
	}
}
.masked-row {
	.page-title__title, .homepage-intro__subtitle, .homepage-cta__paragrafo, .focus__content {
		@extend %transform_left, %transform_child_left;
	}
	&.lista-marcatrici__marcatrice:not(.lista-marcatrici__marcatrice--inverted) .lista-marcatrici__paragrafo {
		@extend %transform_right;	
		> div {
			@extend %transform_child_right;
		}
	}
	.homepage-intro__title, .page-title__intro, &.focus--inverted > .focus__content {
		@extend %transform_right, %transform_child_right;
	}
	&.lista-marcatrici__marcatrice--inverted .lista-marcatrici__paragrafo {
		@extend %transform_left;
		> div {
			@extend %transform_child_left;
		}
	}
	.focus__visual {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
			transform: scaleX(1) translateZ(0);
			transform-origin: right center;
			background-color: #f8f9f9;
			transition: {
				property: transform;
				duration: 1.5s;
			}
			
		}
	}
	&.focus--inverted > .focus__visual {
		&:after {
			transform-origin: left center;
		}
	}
	&.homepage-cta {
		@extend %mask_bg;
	}
	&.lista-marcatrici__marcatrice {	
		@extend %mask_bg;
		&:before {
			left: 0;
			transform-origin: left center;
		}
		&--inverted {
			&:before {
				left: 50%;
				transform-origin: right center;
			}
		}
	}
	&.page-header {
		position: relative;
		overflow: hidden;
		transition: {
			property: transform;
			duration: 1.5s;
		}
		&:before, &:after {
			content: '';
			transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
			position: absolute;
			background-color: #f8f9f9;
			height: 51%;
			width: 100%;
			left: 0;
			transform: scaleX(1) translateZ(0);
		}
		&:before {
			top: 0;
			transform-origin: left center;
		}
		&:after {
			top: 50%;
			transform-origin: right center;
			transition-delay: .5s !important;
		}
	}
	&.unmasked-row {
		&:before, &:after {
			transform: scaleX(0) translateZ(0) !important;
		}
		.page-title__title, .homepage-intro__subtitle, .homepage-cta__paragrafo, .focus__content, .homepage-intro__title, .page-title__intro, .lista-marcatrici__paragrafo--inverted, &.focus--inverted > .focus__content {
			opacity: 1 !important;
			transform: translate3d(0, 0, 0) !important;
			> * {
				transform: translate3d(0, 0, 0) !important;
			}
		}
		.lista-marcatrici__paragrafo {
			opacity: 1 !important;
			transform: translate3d(0, 0, 0) !important;
			> div > * {
				transform: translate3d(0, 0, 0) !important;
			}
		}
		.focus__visual {
			&:after {
				transform: scaleX(0) translateZ(0) !important;
			}
		}
	}
}