// Breakpoint

$medium: 47em;
$large: 64em;

// Global boreder box
html{
    box-sizing: border-box;
    overflow-y: scroll;
}
*,
*:before,
*:after{
    box-sizing: inherit;
}

// Susy

$susy: (
  columns: 12,
  gutters: 1/4,
  math: fluid,
  output: float,
  gutter-position: inside,
);

// Colors

$blu: #13649d;
$grey: #f8f9f9;
$grey--dark: #e6e7e8;
$font-color: #58595b;

// Typography
    
    // Import Google Fonts
    @import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,300i,400,400i,600,600i,700');

$body-font: 'Montserrat', sans-serif;
$header-font: 'Bebas Neue', sans-serif;

html,
body{
    font-family: $body-font;
    font-size: 80%;
    line-height: 1.4;
    color: #333;
    font-weight: 200;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    @include breakpoint($medium){
        font-size: 100%;
    }
}

htlm[lang="ru-RU"] {
  font-size: 70%;
  @include breakpoint($medium){
      font-size: 90%;
  }
  body {
    font-size: 70%;
    @include breakpoint($medium){
        font-size: 90%;
    }
  }
}

body{
  position: relative;
  color: $font-color;
  z-index: 1;
  // p, li, h1, h2, h3, h4, h5, h6, span, div {
  //   [lang="ru-RU"] &, [lang="de-DE"] & {
  //     hyphens: auto;
  //   }
  // }
}
