.main-form{
    @include container(1286px);
    padding: 0 rem(40) rem(60);

    @include breakpoint($medium){
        padding-bottom: rem(150);
    }
}

.wpcf7-form-control-wrap[class*="-hidden"] {
  display: none;
}

[data-class="wpcf7cf_group"] {
  display: inline;
  &.wpcf7cf-hidden {
    display: none;
  }
}

form {
  font-family: $body-font;
  font-weight: 200;
  font-size: rem(24);
  line-height: 1.4;
  color:#939598;
  text-align: left;
  position: relative;
  > *{
    transition: all 0.3s ease-in-out;
  }

  @include breakpoint($medium){
      font-size: rem(45);
      line-height: rem(65);
  }

  .input-container {
    color:$blu;
    padding:6px;
    line-height: 1.4;
    margin-left:-6px;
    margin-right:-6px;
    display:inline-block;
    position:relative;


    
    &.no-overflow {
      max-width:100%;
      border-bottom:1px solid #e7e8e9;
      .input {
        max-width:100%;
        input {
          max-width:100%;
        }
      }
    }
    &.buru, &.filled{
        border-bottom: none;
        
        .placeholder{
            color: $blu;
            &.select-placeholder {
              &:after {
                opacity: 1;
              }
            }
        }
    }
    &.error {
      border-bottom-color: #fca5b1;
      .placeholder{
          color: #fca5b1;
      }
    }
    &:hover {
    //   border-bottom:1px dashed #004f4a;
    //   color:#004f4a;
    //   cursor:pointer;
    }
    &__close {
        text-align: right;
        position: fixed;
        z-index: 9999;
        right: rem(40);
        line-height: 1;
        top: 0;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        transition: visibility .5s, opacity .5s;
        span{
            font-size: rem(16);
            color: $body-font;
            text-transform: uppercase;
            font-weight: 200;
            cursor: pointer;
            position: relative;
            padding-left: rem(30);

            &:before, &:after{
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                height: 1px;
                width: 20px;
                background-color: $blu;
                transform: rotate(45deg) translateY(-50%);
            }

            &:after{
                transform: rotate(-45deg);
            }
        }
    }
    &.active {
      z-index:5000;
      color: $blu;
      cursor:default;
      border-bottom:1px solid $blu;
      .input-container__close {
        visibility: visible;
        opacity: 1;
      }
      &:before {
        content:'';
        display:block;
        background-color: rgba(#fff, 0.9);
        position:fixed;
        z-index:2;
        width:100%;
        height:100%;
        left:0;
        top:0;
      }
      .input {
        visibility:visible;
        opacity:1;
      }

      .newSelect{
        opacity: 1;
      }
    }
    .newSelect{
        display: block;
        // width: auto;
        // position: fixed;
        // opacity: 0;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out;
    }
    .newOption {
      padding:6px 46px 6px 6px;
      position:relative;
      color: #939598;
      transition: color .5s;
      &:hover, &.selected {
        // background:#ff202f;
        color: $blu;
        &:after {
          color:white !important;
        }
      }
    }
    .placeholder {
      position:relative;
      z-index:1;
      color: #e7e8e9;
      transition: color 0.3s ease-in-out;
      cursor: pointer;
      &:hover{
        color: $blu;
      }
      &.select-placeholder{
        padding-right: 18px;
        margin-right: 10px;
        &:after {
          content: "";
          width: 12px;
          height: 12px;
          border-right: 2px solid #939598;
          border-bottom: 2px solid #939598;
          opacity: .7;
          transition: opacity .5s, border-color .5s;
          position: absolute;
          right: 0;
          transform: rotate(45deg) translate3d(0, -50%, 0);
          top: 50%;
        }
        &:hover {
          &:after {
            border-color: $blu;
          }
        }
      }
    }
    .input {
      display:block;
      visibility:hidden;
      position:absolute;
      z-index:3;
      top:0;
      left:0;
      opacity:0;
      transition: visibility .5s, opacity .5s;
      &.selectbox {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9999;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        .wpcf7-form-control-wrap  {
          margin: auto;
        }
      }
    }
  }
  .newOptions{
    cursor: pointer;
  }
  input,select {
    &[type="text"], &[type="tel"], &[type="email"]{
        font-family: $body-font;
        font-size: rem(24);
        line-height: 1.4;
        font-weight: 200;
        border:none;
        outline:none;
        margin:0;
        padding:5px;
        display:block;
        color: $blu;
        @include breakpoint($medium){
            font-size: rem(45);
            line-height: rem(65);
        }
        &:focus {    
          border-bottom:1px solid $blu;
        }
    }
    &.hidden {
      position:absolute;
      left:-5000px;
    }
  }

  textarea {
    font-family: $body-font;
    font-size: rem(24);
    line-height: 1.4;
    font-weight: 200;
    border:none;
    outline:none;
    margin:0;
    padding:5px;
    display:block;
    height: rem(65);
    color: $blu;
    &:focus {    
      border-bottom:1px solid $blu;
    }
    @include breakpoint($medium){
        font-size: rem(45);
        line-height: rem(65);
    }
  }

  .formdivider{
      width: 100%;
      clear: both;
      margin-top: rem(60);

      @include breakpoint($medium){
          margin-top: rem(70);
      }
  }
}

.cf7mls_next.action-button,
.cf7mls_back.action-button {
    @extend .pulsante;
    font-size: rem(16);
    color: $blu;
    text-transform: uppercase;
    background: transparent;
    float: right;

    &:hover{
        color: #fff;
    }
}

input[type="submit"]{
  @extend .cf7mls_next.action-button;
  -webkit-appearance: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
  float: right!important;
}

.cf7mls_back.action-button{
  display: block!important;
  margin: rem(20) auto 0!important;
  float: left;

    @include breakpoint(1195px){
      float: left;
      display: inline-block;
      margin: 0!important;
    }
}

span.wpcf7-not-valid-tip{
  display: none;
}

.legal{
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  top: auto;
  bottom: 0;
  width: 100%;
  flex: 0 0 auto;
  padding-bottom: rem(20);
  @include breakpoint(855px){
      bottom: 0;
      width: 70%;
      padding-top: rem(20);
  }
}

div.wpcf7 span.ajax-loader{
  display: none;
}

.form-footer{
  // @include clearfix;
  height: auto;
  overflow: hidden;
  float: right;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include breakpoint(1194px){
      width: 82%;
      &--full {
        width: 100%;
      }
  }
}

.form-title {
  font-size: rem(20);
  padding-bottom: rem(30);
  color: $blu;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
}

.screen-reader-response {
  display: none;
}

.wpcf7-response-output {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  &.wpcf7-validation-errors {
    color: #fca5b1;
  }
  &.wpcf7-mail-sent-ok {
    color: $blu;
  }
}




.ps-container>.ps-scrollbar-y-rail>.ps-scrollbar-y, .ps-container:hover>.ps-scrollbar-y-rail>.ps-scrollbar-y{
    background-color: $blu;
    border-radius: 0;
}