@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue_thin-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue_light-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_light-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue_book-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_book-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue_regular-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
